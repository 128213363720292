import React from "react";
import '../../index.css';
import 'react-calendar/dist/Calendar.css';
import Footer from "../Footer/Footer";
import Header from "../Header/Header"; 

function Appointment() {
  return (
    <div>
    <div >
     <Header/>  
    </div> 
    <div className="flex flex-col px-5 max-w-[749px] text-stone-900 ">
       <div className="flex justify-center items-center w-screen mt-10">
  <div className="self-end text-5xl font-bold text-center w-[567px] max-md:max-w-full max-md:text-4xl">
    <span className="text-indigo-500">Save Time</span> and Book Now !
  </div>
</div>
      <div className="mt-12 w-full text-2xl font-semibold max-md:mt-10 max-md:max-w-full ml-24">
        Welcome,
              </div>
      </div>
     <div className="flex flex-col justify-center mt-12 ml-28">
        <div className="w-full max-w-md">
          <div className="mb-10">
            <label htmlFor="fullName" className="block text-gray-700  mb-2">Full Name:</label>
            <input type="text" id="fullName" name="fullName" className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mb-10">
            <label htmlFor="phoneNumber" className="block text-gray-700 mb-2">Phone Number:</label>
            <input type="tel" id="phoneNumber" name="phoneNumber" className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mb-10">
            <label htmlFor="address" className="block text-gray-700  mb-2">Address:</label>
            <input type="text" id="address" name="address" className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mb-10">
            <label htmlFor="idType" className="block text-gray-700  mb-2">ID Type:</label>
            <select id="idType" name="idType" className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              <option value="">Select ID Type</option>
              <option value="passport">Passport</option>
              <option value="nationalId">National ID</option>
              <option value="kebeledId">Kebele ID</option>
            </select>
          </div>
          <div className="mb-10">
            <label htmlFor="idNumber" className="block text-gray-700  mb-2">ID Number:</label>
            <input type="text" id="idNumber" name="idNumber" className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mb-10">
            <label className="block text-gray-700  mb-2">Gender:</label>
            <div className="flex items-center">
              <input type="radio" id="male" name="gender" value="male" className="mr-2" />
              <label htmlFor="male" className="mr-4">Male</label>
              <input type="radio" id="female" name="gender" value="female" className="mr-2" />
              <label htmlFor="female">Female</label>
            </div>
          </div>
          
        </div><div className="flex justify-center items-center mt-10">
            <button className="bg-indigo-500  leading-6 text-white px-8 py-3 rounded-md w-1/2">Update Profile</button>
          </div>
      </div>
        <Footer/>
        </div>
);
}
export default Appointment;