import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import '../../index.css';
import { FaBars, FaTimes } from 'react-icons/fa';

function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const linkClasses = (path) => {
        const isActive = location.pathname === path;
        return isActive ? "text-black font-bold text-base my-2" : "hover:text-black text-base my-2 transition-colors duration-200 ease-in-out";
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="bg-white ">
            <div className="flex justify-between items-center p-5 max-w-7xl mx-auto">
                <div className="flex items-center">
                    <img
                        loading="lazy"
                        src="./image1.png"
                        className="shrink-0 aspect-[0.96] w-[57px]"
                        alt="DARS Logo"
                    />
                    <div className="ml-3 text-2xl font-semibold text-indigo-600">DARS.</div>
                </div>
                <div className="hidden md:flex gap-5 items-center text-sm leading-4 text-slate-500">
                    <Link to="/" className={linkClasses("/")}>Home</Link>
                    <Link to="/appointment" className={linkClasses("/appointment")}>Book an appointment</Link>
                    <Link to="/contactus" className={linkClasses("/contactus")}>Contact Us</Link>
                    <Link to="/aboutus" className={linkClasses("/aboutus")}>About Us</Link>
                    <Link to="/Profile" className={linkClasses("/Profile")}>Profile</Link>
                    <Link to="/bookinghistory" className={linkClasses("/bookinghistory")}>Booking history</Link>
                </div>
                <div className="hidden md:flex gap-5">
                    <button className="bg-indigo-500 text-white rounded-md px-4 py-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out" onClick={() => navigate('/SignUp')}>Sign Up</button>
                    <button className="bg-indigo-500 text-white rounded-md px-4 py-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out" onClick={() => navigate('/Login')}>Sign In</button>
                </div>
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMenu} className="text-2xl">
                        {menuOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>
            </div>
            {menuOpen && (
                <div className="md:hidden bg-white shadow-md p-5">
                    <button className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out" onClick={() => {navigate('/'); toggleMenu();}}>Home</button>
                    <button className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out" onClick={() => {navigate('/appointment'); toggleMenu();}}>Book an appointment</button>
                    <button className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out" onClick={() => {navigate('/contactus'); toggleMenu();}}>Contact Us</button>
                    <button className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out" onClick={() => {navigate('/aboutus'); toggleMenu();}}>About Us</button>
                    <button className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out" onClick={() => {navigate('/Profile'); toggleMenu();}}>Profile</button>
                    <button className="w-full text-left text-base my-2 hover:text-black transition-colors duration-200 ease-in-out" onClick={() => {navigate('/bookinghistory'); toggleMenu();}}>Booking history</button>
                    <button className="bg-indigo-500 text-white rounded-md w-full py-2 mt-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out" onClick={() => {navigate('/SignUp'); toggleMenu();}}>Sign Up</button>
                    <button className="bg-indigo-500 text-white rounded-md w-full py-2 mt-2 hover:bg-indigo-600 transition-colors duration-200 ease-in-out" onClick={() => {navigate('/Login'); toggleMenu();}}>Sign In</button>
                </div>
            )}
        </div>
    );
}

export default Header;
