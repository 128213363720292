import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import '../../index.css';
import 'react-calendar/dist/Calendar.css';
import LocationDropdown from "./Locationdropdown";
import Calander from "./Calander";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function Appointment() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };   
  const navigate = useNavigate();
  
  return (
    <div>
      <Header/>  
      <div className="flex flex-col px-5 max-w-[749px] text-stone-900 ">
        <div className="flex justify-center items-center w-screen mt-10">
          <div className="self-end text-5xl font-bold text-center w-[567px] max-md:max-w-full max-md:text-4xl">
            <span className="text-indigo-500">Save Time</span> and Book Now!
          </div>
        </div>
        <div className="mt-12 w-full text-2xl font-semibold max-md:mt-10 max-md:max-w-full ml-24">
          Welcome,
        </div>
      </div>
      <LocationDropdown />
      <Calander className="ml-28" selectedDate={selectedDate} />



      <div className="flex flex-row items-center justify-center mt-20">
        <button 
          className="bg-indigo-500 font-bold leading-6 text-white px-8 py-3 rounded-md w-1/2" 
          onClick={() => navigate('/Confirmation')}
        >
          Save Appointment
        </button>
      </div>
      <Footer/>
    </div>
  );
}

export default Appointment;
