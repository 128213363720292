import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectLabels() {
  const [selectLocation, setSelectLocation] = React.useState('');
  const [selectService, setSelectService] = React.useState('');

  const locationOptions = [
    'Lancha',
    'Masalemya',
    'Megenagna',
    'Sidist Kilo',
    'Gulele',
    'Ayertena',
    'Kaliti',
    'Kasanchis',
    'Ledeta',
    'Semien Hotel',
    'Bole',
    'Bambis',
    'Kera',
    'Semit',
  ];

  const serviceOptions = [
    'Document authentication',
    'Family Representation',
    'General Representation',
    'Sales',
  ];

  const handleLocationChange = (event) => {
    setSelectLocation(event.target.value);
  };

  const handleServiceChange = (event) => {
    setSelectService(event.target.value);
  };

  return (
    <div className="flex flex-col md:flex-row items-center mt-16 gap-8 px-4 md:px-28">
      <FormControl className="w-full md:w-1/3">
        <InputLabel id="location-select-label">Select Location</InputLabel>
        <Select
          labelId="location-select-label"
          id="location-select"
          value={selectLocation}
          label="Select Location"
          onChange={handleLocationChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                overflow: 'auto',
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          <MenuItem value="">
            <em>Select Location</em>
          </MenuItem>
          {locationOptions.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="w-full md:w-1/3">
        <InputLabel id="service-select-label">Select Service</InputLabel>
        <Select
          labelId="service-select-label"
          id="service-select"
          value={selectService}
          label="Select Service"
          onChange={handleServiceChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                overflow: 'auto',
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          <MenuItem value="">
            <em>Select Service</em>
          </MenuItem>
          {serviceOptions.map((service) => (
            <MenuItem key={service} value={service}>
              {service}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
