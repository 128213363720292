import React, { useState } from 'react';
import { CloseOutlined} from '@ant-design/icons';

const Paymentmodal = ({ isOpen, onClose }) => {
  const [amount, setAmount] = useState('');
  if (!isOpen) return null;
  const handlePay = (paymentMethod) => {
    console.log(`Paying with ${paymentMethod} for an amount of ${amount}`);
    onClose();
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <div className="flex justify-end mb-4">
          <button onClick={onClose}>
            <CloseOutlined className="text-gray-500 hover:text-gray-700" />
          </button>
        </div>
        <h2 className="text-2xl font-bold mb-4">Pay Your Service Fee</h2>
        <div className="mb-4">
          <label htmlFor="amount" className="block font-medium mb-2">
            Amount:
          </label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="Enter amount"
          />
        </div>
        <div className="flex flex-row space-x-6">
             <div className="flex  ">
            <button
              
              onClick={() => handlePay('Commercial Bank of Ethiopia Logo')}
            >
              <img
            loading="lazy"
            src="./Commercial Bank of Ethiopia Logo(1).png"
            className="mt-8"
            alt="Commercial Bank of Ethiopia Logo" 
          />
            </button> 
          </div>
          <div className="flex ">
            <button
              onClick={() => handlePay('cbebirr')}
            >
              <img
            loading="lazy"
            src="./CBE Birr ( No background ) Logo(1).png"
            className="mt-8 "
            alt="cbebirr Logo" 
          />
            </button>
          </div>
           <div className="flex  ">
            <button
              onClick={() => handlePay('telebirr')}
            >
              <img
            loading="lazy"
            src="./TeleBirr Logo(1).png"
            className="mt-8 "
            alt="telebirr Logo" 
          />
            </button>  
          </div>
          <div className="flex">
            <button   
              onClick={() => handlePay('Chapa')}
            >
              <img
            loading="lazy"
            src="./Chapa Logo.png"
            className="mt-8 w-1/2 "
            alt="Chapa Logo" 
          />
            </button>
          </div>
              </div>
              <div className="flex justify-center mt-4 ">
          <button
            className="bg-indigo-600 w-full text-white font-bold py-2 px-4 rounded"
            onClick={() => handlePay('amount')}
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  );
};

export default Paymentmodal;