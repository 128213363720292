import * as React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function SignUp() {
  const navigate = useNavigate();
  const [fullName, setFullName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [isOTPSent, setIsOTPSent] = React.useState(false);

  const handleGetCode = async () => {
    try {
      await axios.post("/send-otp", { phoneNumber });
      setIsOTPSent(true);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleSignUp = async () => {
    try {
      await axios.post("/verify-otp", {
        phoneNumber,
        verificationCode,
      });
      navigate("/");
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  return (
    <div className="flex flex-col px-16 py-6 bg-white max-md:px-5">
      <div className="flex gap-2.5 self-start text-xl font-medium text-center text-indigo-500 whitespace-nowrap">
        <img
          loading="lazy"
          src="./image1.png"
          className="shrink-0 aspect-[0.96] w-[57px]"
          alt="logo"
        />
        <div className="my-auto">DARS.</div>
      </div>
      <div className="self-center mt-11 text-3xl font-bold text-black max-md:mt-10">
        Create an account
      </div>
      <div className="self-center mt-11 w-full max-w-[947px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[59%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="/images11.jpg"
              className="aspect-[0.99] max-md:mt-10 max-md:max-w-full"
              alt="logo"
            />
          </div>
          <div className="flex flex-col ml-5 w-[41%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow py-0.5 text-xs max-md:mt-10">
              <label htmlFor="fullName" className="block text-gray-700 mb-2">
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <div className="shrink-0 mt-2.5 h-0.5 bg-indigo-500" />
              <label htmlFor="phoneNumber" className="block text-gray-700 mt-8">
                Phone Number
              </label>
              <input
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
               
              {isOTPSent ? (
               <div>
             <div className="shrink-0 mt-2.5 h-0.5 bg-indigo-500"/>
              <label
                htmlFor="verificationCode"
                className="block text-gray-700 mt-8"
              >
                Verification Code
              </label>
                <input
                type="number"
                id="verificationCode"
                name="verificationCode"
                className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              /> 
            </div>
                 
          ) : (
           <div className="flex z-10 gap-0 px-px text-sm">
                <button
                  className="px-4 py-1 font-semibold text-center text-white bg-indigo-500 rounded-lg ml-64"
                  onClick={handleGetCode}
                >
                  Get Code
                </button>
              </div>
          )}
                  
              </div>
              <div className="shrink-0 mt-1.5 h-0.5 bg-indigo-500" />
              <button
                className="justify-center items-center px-12 py-3.5 mt-36 text-sm font-semibold text-center text-white whitespace-nowrap bg-indigo-500 rounded-lg max-md:px-5 max-md:mt-10"
                onClick={handleSignUp}
              >
                SignUp
              </button>
              <div className="justify-center items-end px-16 py-7 mt-28 bg-slate-50 max-md:px-5 max-md:mt-10">
                <span className="">Already have an account? </span>
                <button
                  className="text-indigo-500 underline"
                  onClick={() => navigate("/Login")}
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
}

export default SignUp;