import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landingpage from './Components/LandingPage/Landingpage';
import Appointment from './Components/Appointment/Appointment';
import Confirmation from './Components/Appointment/Confirmation';
import AboutUs from './Components/Aboutus/Aboutus';
import ContactUs from './Components/Contactus/Contactus';
import Profile from './Components/Profile/Profile';
import SignUp from './Components/SignUp/SignUp';
import Login from './Components/LogIn/Login';
import PIN from './Components/LogIn/PIN';
import ForgetPIN from './Components/LogIn/ForgetPIN';
import PINReset from './Components/LogIn/PINReset';
function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/Appointment" element={<Appointment />} /> 
         <Route path="/Confirmation" element={<Confirmation />} />
        <Route path="/Aboutus" element={<AboutUs />} />
        <Route path="/Contactus" element={<ContactUs />} />
         <Route path="/Profile" element={<Profile />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/PIN" element={<PIN />} />
        <Route path="/ForgetPIN" element={<ForgetPIN />} />
        <Route path="/PINReset" element={<PINReset />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;



