import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-calendar';

const Calander = ({ selectedDate, times = [] }) => {
  const [selectedTime, setSelectedTime] = useState(selectedDate);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedAvailableTime, setSelectedAvailableTime] = useState(null);

  const disabledDates = ['2024-06-10', '2024-06-05', '2024-06-07'];

  const sampleAvailableTimeSlots = {
    '10:00': 4,
    '15:00': 2,
    '18:00': 6
  };

  const isDateDisabled = (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const isWeekend = date.getDay() === 0 || date.getDay() === 6; // 0 is Sunday, 6 is Saturday
    return disabledDates.includes(formattedDate) || isWeekend;
  };

  const handleDateChange = (date) => {
    setSelectedTime(date);
    const sampleAvailableTimes = Object.keys(sampleAvailableTimeSlots);
    setAvailableTimes(sampleAvailableTimes);
    setSelectedAvailableTime(null); // Reset the selected available time
  };

  const handleTimeSelection = (time) => {
    setSelectedAvailableTime(time);
  };

  return (
    <div className="calendar-container p-4 bg-white rounded-lg  flex flex-col md:flex-row items-center mt-6 gap-28 px-4 md:px-28">
      <DatePicker
        value={selectedTime}
        onChange={handleDateChange}
        minDate={new Date()}
        tileDisabled={({ date }) => isDateDisabled(date)}
        className="w-full h-80 p-2 border border-gray-300 rounded-lg"
      />

      {availableTimes.length > 0 && (
        <div className="available-times ">
          <h3 className="text-xl font-semibold mb-2">Available Times:</h3>
          <ul className="space-y-2">
            {availableTimes.map((time, index) => (
              <li key={index} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="available-time"
                  value={time}
                  checked={selectedAvailableTime === time}
                  onChange={() => handleTimeSelection(time)}
                  className="form-radio h-4 w-4 text-indigo-600"
                />
                <label className="text-gray-700">
                  {time} ({sampleAvailableTimeSlots[time]} {sampleAvailableTimeSlots[time] === 1 ? 'slot' : 'slots'})
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Calander;
