import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { PhoneOutlined, MailOutlined, EnvironmentOutlined, TwitterOutlined, FacebookOutlined, LinkedinOutlined } from '@ant-design/icons';

const Contactus = () => {
  return (
    <div>
      <Header />
      <div className="flex flex-col px-5 max-w-[749px] text-stone-900">
        <div className="flex justify-center items-center w-screen mt-10">
          <div className="self-end text-5xl font-bold text-center w-[567px] max-md:max-w-full max-md:text-4xl">
            <span className="text-indigo-500">Any inquiries?</span>
          </div>
        </div>
        <div className="flex justify-center items-center w-screen mt-4">
          <div className="self-start text-lg font-bold text-center w-[567px] max-md:max-w-full max-md:text-base text-gray-400">
            Any question or feedback? Just write us a message!
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full mt-8">
        <div className="w-full max-w-[749px] bg-white rounded-lg flex max-md:flex-col">
          <div className="bg-indigo-500 w-1/2 rounded-l-lg p-12 flex flex-col pt-12 pb-4 max-w-[491px] max-md:w-full max-md:rounded-l-lg max-md:rounded-r-none max-md:pb-12">
            <div className="flex flex-col px-10 w-full text-base text-white max-md:px-5 max-md:max-w-full">
              <div className="text-3xl font-semibold">Contact Information</div>
              <div className="mt-6 text-lg text-stone-200">
                Say something for any inquiries
              </div>
              <div className="flex gap-5 self-start mt-8 whitespace-nowrap max-md:mt-10">
                <PhoneOutlined />
                <div className="flex-auto my-auto">+(251) 115122412</div>
              </div>
              <div className="flex gap-5 mt-12 whitespace-nowrap max-md:mt-10">
                <MailOutlined />
                <div className="flex-auto">dara_gov@ethio.et</div>
              </div>
              <div className="flex gap-5 mt-12 max-md:mt-10">
                <EnvironmentOutlined />
                <div className="flex-auto">Addis Ababa, Ethiopia</div>
              </div>
              <div className="flex flex-col">
                <div className="shrink-0 self-end mt-10 rounded-full bg-stone-50 bg-opacity-10 h-[138px] w-[138px] max-md:hidden" />
                <div className="shrink-0 self-end rounded-full bg-stone-50 bg-opacity-10 h-[35px] w-[35px] mt-12 max-md:hidden" />
              </div>
            </div>
            <div className="flex gap-5 justify-between items-start self-start mt-1 ml-10 max-md:ml-2.5 max-md:mt-16">
              <TwitterOutlined />
              <FacebookOutlined />
              <LinkedinOutlined />
            </div>
          </div>
          <div className="bg-gray-200 w-1/2 rounded-r-lg flex flex-col items-end justify-center p-12 max-md:w-full max-md:rounded-l-none max-md:rounded-b-lg max-md:pt-0 max-md:pb-12 max-md:px-5 ">
            <input
              type="text"
              placeholder="First name"
              className="w-full bg-gray-200 border-b border-gray-500 text-gray-500 placeholder-gray-500 mb-6 p-2 max-md:mt-12"
            />
            <input
              type="text"
              placeholder="Last Name"
              className="w-full bg-gray-200 border-b border-gray-500 text-gray-500 placeholder-gray-500 mb-6 p-2"
            />
            <input
              type="tel"
              placeholder="Phone Number"
              className="w-full bg-gray-200 border-b border-gray-500 text-gray-500 placeholder-gray-500 mb-6 p-2"
            />
            <input
              type="email"
              placeholder="Email"
              className="w-full bg-gray-200 border-b border-gray-500 text-gray-500 placeholder-gray-500 mb-6 p-2"
            />
            <textarea
              placeholder="Message"
              className="w-full bg-gray-200 border-b border-gray-500 text-gray-500 placeholder-gray-500 mb-6 p-2 h-32 resize-none"
            ></textarea>
            <button className="bg-indigo-500 font-bold leading-6 text-white px-8 py-3 rounded-md w-full mt-6">
              Send Message
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;