import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const AboutUs = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold mb-8">Who we are</h1>
        <p>
          Documents Authentication and Registration Office is committed to implement<br />
          ISO 9001:2008 QMS and to provide the highest quality and credible Documents <br />
          Authentication and Registration service, to all customers in conformance with<br />
          customers and applicable legal requirements, involving a process of continual <br />
          improvement and maintenance
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center mt-16">
        
            <h2 className="text-2xl font-bold mb-2"> Mission</h2>
            <p>
              Provide a reliable means of evidence through 
                authentication and registration of documents,
                to facilitate contractual and other relations 
                between persons and organizations both
                at domestic and international level and thus
                support the justice system and investment,
                besides ensuring the availability of documents
                whenever they are required is our Mission
            </p>
          </div>
          <div className="text-center mt-16">
           
            <h2 className="text-2xl font-bold mb-2"> Vision</h2>
            <p>
             To see the office being a mode institution
            by 2014, in satisfying customers through authenticated and registered documents service provision based on international standards is our Vision
            </p>
          </div>
          <div className="text-center mt-16">
          
            <h2 className="text-2xl font-bold mb-2">Core values</h2>
            <p>
            We strongly believe customer is a king, Honesty, Integrity, Transparency, and Quality service is our manifestation,
            We strive to ensure customers satisfaction in a dynamic change, We believe in synergy performance, We continually develop
            our capacity and effectiveness, We believe on gender equality, We work on HIV/AIDS Prevention, Good governance is our Principle,
            We believe on team work, We give attention to Information, Saving is our culture. These are our Core Values

            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;