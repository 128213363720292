import React, { useState } from "react";
import '../../index.css';
import Footer from "../Footer/Footer";
import Header from "../Header/Header"; 
import PaymentModal from './Paymentmodal';

function Confirmation() {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const handlePayNowClick = () => {
    setIsPaymentModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsPaymentModalOpen(false);
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="flex flex-col px-5 max-w-[749px] text-stone-900 ">
        <div className="flex justify-center items-center w-screen mt-10">
          <div className="self-end text-5xl font-bold text-center w-[567px] max-md:max-w-full max-md:text-4xl">
            <span className="text-indigo-500">Save Time</span> and Book Now !
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-12 w-full text-2xl font-semibold max-md:mt-10 max-md:max-w-full">
  <div className="mt-12 md:w-1/2 h-full md:ml-16 border rounded-md p-6 md:mr-20">
    <p className="text-2xl font-semibold">Confirm your appointment</p>
    <div className="mt-8">
      <div className="flex justify-between mb-6 text-xl ">
        <span className="font-light">Service Type:</span>
        <span>Sales</span>
      </div>
      <div className="flex justify-between mb-6 text-xl ">
        <span className="font-light">Scheduled for:</span>
        <span>June 15, 2024 at 10:00 AM</span>
      </div>
      <div className="flex justify-between mb-6 text-xl ">
        <span className="font-light">Location:</span>
        <span>Lancha</span>
      </div>
    </div>
  </div>
  <div className="mt-12 md:w-1/2 h-full md:mr-16 border rounded-md p-6">
    <p className="text-2xl font-semibold">Payment Summary</p>
    <div className="mt-8">
      <div className="flex justify-between mb-24 text-xl ">
        <span className="font-light">Total appointment fee:</span>
        <span>50.00 ETB</span>
      </div>
      <div className="flex flex-col items-center mt-4">
        <button className="bg-red-400 text-white text-xl py-2 px-4 rounded mb-2 w-full">
          Request Cancellation
        </button>
        <button
          className="bg-indigo-500 text-white text-xl py-2 px-4 rounded w-full"
          onClick={handlePayNowClick}
        >
          Pay Now
        </button>
      </div>
    </div>
  </div>
</div>


      <PaymentModal isOpen={isPaymentModalOpen} onClose={handleCloseModal} />

      <Footer />
    </div>
  );
}

export default Confirmation;